import React from "react";
import classes from "./styles.module.scss";
import LeftBar from "./leftBar";
import RightContent from "./rightContent";

const Layout = (props) => {
    return(
        <div className={classes.layout_container}>
            <LeftBar />
            <RightContent type={props.type}>
                {props.children}
            </RightContent>
        </div>
    )
}

export default Layout;