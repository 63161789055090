import React from "react";
import classes from "./styles.module.scss";
import BrandImage from "../../../assets/logo.png";
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import Menu from "./menu";
import { useNavigate } from "react-router-dom";

const LeftBar = () => {

    const navigate = useNavigate();

    return(
        <div className={classes.left_bar_container}>
            <div className={classes.left_bar_content}>
                <div className={classes.brand_logo}>
                    <img src={BrandImage} alt="brandLogo" />
                </div>
                <div className={classes.menu_section}>
                    <Menu />
                </div>
                <div className={classes.setting_logout}>
                    <ul>
                        <li onClick={() => navigate("/logout")}>
                            <span><PowerSettingsNewRoundedIcon /></span>
                            <p>Logout</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default LeftBar;