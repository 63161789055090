
import { apiFunction } from "../../apiCall/function";
import {
    LOGIN, GET_TOKEN, GET_PERMISSION, GET_DISTRICT
} from "../../apiCall/urls/auth";

export const handleLogin = (data) => {
    return apiFunction(LOGIN, "post", data, null, null);
};

export const getDistrict = () => {
    return apiFunction(GET_DISTRICT, "get", {}, null, null);
};