import React from "react";
import classes from "./styles.module.scss";

const RightContent = (props) => {
    return(
        <div className={classes.right_content}>
            {props.children}
        </div>
    )
}

export default RightContent