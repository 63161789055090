import React, { lazy, useEffect, useState, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Navigate } from 'react-router-dom';

import Layout from "../container/layout";
import { useLocation } from "react-router-dom";

const ReportPage = lazy(() => import("../pages/admin/reportPage"))
const UploadPage = lazy(() => import("../pages/admin/uploadPage"))

const LoginPage = lazy(() => import("../pages/loginPage"))
const Logout = lazy(() => import("../pages/logout"))

const openRoutes = [
    { path: "/", exact: true, component: LoginPage },
]

const homeRoutes = [
    { path: "/result", exact: true, component: ReportPage },
    { path: "/upload", exact: true, component: UploadPage },
    { path: "/logout", exact: true, component: Logout },
]

const PagesRoute = (props) => {
    const location = useLocation()
    const [allRoutes, setAllRoutes] = useState([])
    const [isHome, setHome] = useState(false)

    useEffect(() => {
        setAllRoutes([...openRoutes, ...homeRoutes])
    }, [])

    console.log(window.location.pathname);

    const generateRoute = (allRoutes) => {
        let _data = [];
        allRoutes.map(({ path, component: Component, navigate, ...route }, i) => {
            _data.push(<Route
                path={path}
                key={i}
                exact={true}
                element={
                    navigate ? <Navigate replace to="/dashboard/question" /> :
                        <Suspense fallback={<></>}>
                            <Component />
                        </Suspense>
                }
            />)
        })
        return <Routes>
            {_data}
        </Routes>

    }

    return (
        <div>
            {
                window.location.pathname === "/" ?
                    <>{generateRoute(allRoutes)}</> :
                    <Layout>
                        {generateRoute(allRoutes)}
                    </Layout>
            }

        </div>
    );
};

export default PagesRoute;