import React from "react";
import classes from "./styles.module.scss";
import { NavLink } from "react-router-dom";

import Policy from '@mui/icons-material/Policy';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { connect } from "react-redux"

import "./active.css";

const Menu = (props) => {
    const menuData = [
        {
            title: "Upload",
            icon: <SearchRoundedIcon />,
            toLink: "/upload",
            section: "Upload"
        },
        {
            title: "Result",
            icon: <Policy />,
            toLink: "/result",
            section: "result"
        }
    ]

    return (
        <ul className={classes.menu_container}>
            {
                menuData.map((item, index) => {
                    return (
                        <li key={index} className={item.cName}>
                            <NavLink to={item.toLink} className="link">
                                <span className={classes.icon}>{item.icon}</span>
                                <p>{item.title}</p>
                            </NavLink>
                        </li>
                    )
                })
            }
        </ul>
    )
}

const mapStateToProps = state => {
    return { auth: state.Auth }
}
const mapDispatchToProps = dispatch => {
    return {
        // setRoute: (route) => dispatch(currentRoute(route))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu)