import * as actionTypes from "./actionTypes";
import { actionNotifier } from "../../components/ui/toast";
import { handleLogin } from "./apis";
import { apiLoading, apiLoadingEnd } from "../../store/notification/action";

export const onLogin = (state, navigate) => {
    return async (dispatch) => {
        dispatch(apiLoading())
        
        let result = await handleLogin(state)
        if (result.status) {
            localStorage.setItem("bepc-omr-token", JSON.stringify(result.data))
            dispatch(updateAuthData(result.data));
            window.open("/upload", "_self")
        } else {
            actionNotifier.error(result.message);
        }
        dispatch(apiLoadingEnd());
    };
};

export const onLogout = (navigate) => {
    return (dispatch) => {
        localStorage.removeItem("bepc-omr-token")
        localStorage.clear();
        dispatch(resetAuth())
        navigate("/")
    }
};

export const updateAdminFromLocal = () => {
    return (dispatch) => {
        let token = localStorage.getItem("bepc-omr-token")
        if (token) {
            token = JSON.parse(token)
            dispatch(updateAuthData(token))
        }
    }
};


export const updateAuthData = (data) => {
    return {
        type: actionTypes.UPDATE_AUTH_DATA,
        payload: data,
    };
};


export const resetAuth = (data) => {
    return {
        type: actionTypes.RESET,
    };
};

export const updateReducer = (key, data) => {
    return {
        type: actionTypes.UPDATE_REDUCER,
        payload: { key, data }
    };
};




