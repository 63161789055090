import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./components/ui/overlay"
import Toast from "./components/ui/toast";
import Routes from "./routes";
import { updateAdminFromLocal } from "./store/auth/actions"

import "react-toastify/dist/ReactToastify.css";
import './App.css';

function App(props) {
    useEffect(() => {
        props.updateAdminFromLocal()
    }, [])

    return (
        <div className="App">
            <Routes />
            <Toast />
            {props.notification.loading ? <Loader active={props.notification.loading} /> : null}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        notification: state.Notification,
        auth: state.Auth,
    }
}

const mapDispatchToProps = { updateAdminFromLocal }

export default connect(mapStateToProps, mapDispatchToProps)(App);